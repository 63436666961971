<template>
  <div class="logout">
    <h4>
      {{ $t('pages.auth.logging_out') }}
    </h4>
  </div>
</template>
<script>
export default {
  layout: 'standalone',

  middleware: 'auth',

  beforeMount() {
    this.logout();
  },

  methods: {
    async logout() {
      try {
        await this.$auth.logout();
        this.$notify.success(this.$t('notifications.auth.logged_out'));
        await this.$router.push({ name: this.$RouteEnum.LOGIN });
      } catch (e) {
        this.$axios.handleError(e);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.logout {
  width: 100%;
  height: 100%;
  display: flex;
  background: var(--background-color);
  flex-wrap: wrap;

  h4 {
    margin: auto;
  }
}
</style>
